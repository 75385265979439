import { toUpper } from "lodash";
import fs from "fs";

export const WriteLogs = {
  error(error) {
    let current_datetime = new Date();
    let formatted_date =
      current_datetime.getFullYear() +
      "-" +
      (current_datetime.getMonth() + 1) +
      "-" +
      current_datetime.getDate() +
      " " +
      current_datetime.getHours() +
      ":" +
      current_datetime.getMinutes() +
      ":" +
      current_datetime.getSeconds();

    let method = toUpper(error.config.method);
    let url = error.config.url;
    let status = error.data.status;
    let log = `[${formatted_date}] ${method}:${url} ${status} ERROR: ${error.data.message}`;

    let fileName =
      current_datetime.getFullYear() +
      "_" +
      (current_datetime.getMonth() + 1) +
      "_" +
      current_datetime.getDate() +
      ".log";

    fs.appendFile("./log/" + fileName, log + "\n", (err) => {
      if (err) {
        console.log(err);
      }
    });
  },
};
