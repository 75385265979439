import store from "../store";

export const authorization = {
  validateGrant(key) {
    if (store.state.currentBu.authorizations[key] == "ACCESSIBLE") {
      return true;
    } else {
      false;
    }
  }
}
