// import maps from "./maps";
import time from "./time";
import icons from "./icons";
import theme from "./theme";
import toolbar from "./toolbar";
import locales from "./locales";
// import analytics from "./analytics";
import currencies from "./currencies";
import navigation from "./navigation";

export default {
  // product display information
  product: {
    name: "Osmose"
  },

  // google maps
  // maps,

  // time configs
  time,

  // icon libraries
  icons,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // locales configs
  locales,

  // analytics configs
  // analytics,

  // currencies configs
  currencies,

  // navigation configs
  navigation
};
