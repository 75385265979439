import store from "../store";

export const rateBilling = {
  validateRateBilling(key) {
    if (store.state.rateBilling != null) {
      // console.log(store.state.rateBilling.modules);
      return store.state.rateBilling.modules.includes(key);
    }
    return false;
  },
};
