export default [
  {
    path: "/my-management",
    component: () =>
      import("@/pages/managementOrganization/IndexManagementOrganization.vue"),
    beforeEnter: (to, from, next) => {
      const store = require("../store/index");
      const typeBu = store.default.state.currentBu.tenant.type;
      if (typeBu == "TENANT") {
        next();
      } else {
        next({ name: "myOsmose" });
      }
    },
    meta: {
      layout: "management",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "myManagement",
        component: () =>
          import("@/pages/managementOrganization/MyManagement.vue"),
        meta: {
          layout: "management",
          requiresAuth: true,
        },
      },
    ],
  },
];
