export default {
  form: {
    no: "no",
    yes: "yes",
    close: "close",
    send: "send",
    delete: "delete",
    search: "search",
    required: "require",
    validate: "validate",
    cancel: "cancel",
    fieldsRequired: "certain mandatory fields are not filled in",
    invalidEmail: "invalid email",
    noAction: "no action possible",
    securityPasswordDifferent: "passwords are different",
    or: "or",
    success: "operation carried out",
    error: "error",
    errorSelect: "select a field",
    previous: "previous",
    next: "next",
    select: "select",
  },

  formRules: {
    required: "required",
    requiredNumber: "required",
    requiredSelectMultiple: "required selection",
    invalidEmail: "email non valide",
    maxCharacters: "caractères maximum",
    passeNotIdentical: "les mots de passe ne sont pas identiques",
    numberMinRequired: "ne peut être inférieur à",
    numberMaxRequired: "ne peut être suppérieur à",
  },

  uploadFile: {
    sendingCompleted: "the file have been sent",
    multipleSendingCompleted: "the files have been sent",
    listUploadFiles: "list uploaded files",
    success_infoSendingFile:
      "your file is now being analyzed, you will be notified when it is for review",
    success_infoSendingFiles:
      "your files are now being analyzed, you will be notified when they are for review",
    maxSize: "the file exceeds {size} Mo",
    error_multipleFiles: "of your files could not be sent",
    error_singleFiles: "of your files could not be sent",
  },
  convertDate: {
    years: "years",
    month: "months",
    day: "day",
    dayss: "days",
    hours: "hours",
    thereIs: "there is",
    in: "in",
    and: "and",
    year: "year",
    from: "from",
    to: "to",
    every: "every",
    fromThe: "from the",
    the: "the",
    minute: "minutes",
  },
  date: {
    monday: "lundi",
    tuesday: "mardi",
    wednesday: "mercredi",
    thursday: "jeudi",
    friday: "vendredi",
    saturday: "samedi",
    sunday: "dimanche",
  },

  tags: {
    management: "gestion des tags", //TODO à traduire.
    create: "cliquer sur entrer pour ajouter le tag", //TODO à traduire.
    dialogTitle: "gestion des tags", //TODO à traduire.
    errorMessages: {
      alreadyPresent: "ajout impossible ! le tag est déjà présent", //TODO à traduire.
      notStartSpace: "the name must not start with a space",
    },
  },
  generateMenu: {
    noAction: "no action",
  },
  generateForm: {
    fieldsRequired: "some mandatory fields are not filled in",
  },
  support: {
    support: "support",
    helpSupport: "contact support",
    generateSupportLink: "access support creation",
    keySupport: "access support",
    keyMsg: "please copy this code and pass it on to support",
    copyToClipboard: "copy to clipboard",
    close: "close",
    msgToClipBoard: "link copy to clipboard",
    generateCodeSupport: "generate a support code",
    regenerateCodeSupport: "regenerate a support code",
    generateAbout:
      "the button below generates a code that you can send to support so that they can access your space",
  },
  cguCgv: {
    tos: "terms of service",
    policy: "privacy policy",
    agreeCGV: "i accept the",
  },
  password: {
    passwordPolicyMinLen:
      "your password must contain at least {size} characters",
    passwordPolicyPattern: "your password don't fill all the condition",
    passwordPolicyMaxLen:
      "your password canno't contain more of {size} characters",
    passwordPolicyMinUpper:
      "your password must contain at least {size} upper case character",
    passwordPolicyMinDigit: "your password must contain at least {size} number",
    passwordPolicyMinSpecial:
      "your password must contain at least {size} special character",
    passwordPolicyHistory:
      "your password canno't be one you used during the last {size}",
  },
  auth: {
    welcome: "welcome",
    choiceTenants: "choose your workspace",
    returnSignInPage: "Get back on sign in page",
    forgotPassword: "forgot password ?",
    resetPass: "reset",
    resetPassForm: "to change the password",
    changePass: "password customization",
    expiredPass: "your password has expired",
    confirmYour: "confirm your",
    loginPage: "signin page",
    signupPage: "signup page",
    forgotPage: "forgot password",
    loginButton: "sign in",
    logout: "logout",
    noAccount: "you don't have an account ?",
    createAccount: "create one here",
    registrationButton: "sign up",
    accountAlreadyExist: "you already have an account",
    signinRedirection: "connect you",
    forgotSubtitle:
      "Enter your account email address and we will send you a link to reset your password.",
    signInNOK:
      "connection error, check if your account is validate or if the login / password pair is correct",
    changePassOk: "your password has been modify",
    tokenExpired: "session time ended please reconnect",
    passeNotIdentical: "the passwords are not the same",
    toAllow: "you are not authorized to access this resource",
    youHaveDaysLeftChangePwd:
      "you have {days} days left to change your password",
    changePwd: "do you want to change it ?",
    editMyPassword: "edit my password",
    signUpOk: "your registration has been taken into account",
    userDisabled: "your account is desactivated, please contact the support.",
    securityUserUnknown: "the account does not exist",
    securityTokenInvalid: "your session has expired, please reconnect",
    securityAccessDenied:
      "you are not allowed to access to the last organisation or service which you visited the last time you used Oblige, please try to reconnect you. ",
    dataTooMany: "email is undeterminated (multiples)",
    accountExists: "an account with this address already exists",
    modalLiveText: "the account attached to the",
    modalLiveTextAfter:
      "adress already. If you forget you can change your password. In this case an email will be sent to you containing a temporary password.",
    modalLiveTextAction: "would you like to reset the password ?",
    accountUnconfirmedSignUp: "this account exist but has never been validate",
    textSignUp:
      "we have taken into account your request to create an account, you will receive an email at",
    textSignUpAfter:
      "very soon containing the instructions to follow in order to acces to Osmose",
    titlePwdForgot: "password change made",
    textPwdForgot: "an email as been sent to",
    textPwdForgotAfter:
      "address containing your tomporary password which you will need to change the next time you log in",
    registrationDate: "first time registration :",
    pwdValidateDuration: "your new password will be valid for",
    userDisabledTitle: "user account disable",
    renewCredentialsExpired:
      "It is possible that the password renewal period has expired. In that cas please click on forgot password.  ",
    passwordPolicyHistory: "not be used on the last {value}",
    passwordPolicyMinDigit: "contain at least {value} number",
    passwordPolicyMinLen: "contain at least {value} character",
    passwordPolicyMinSpecial: "contain at least {value} special character",
    passwordPolicyMinUpper: "contain at least {value} upper case character",
    passwordValidity: "it will be valid for {value}",
    passwordHadToContain: "your password must contain :",
  },
  utility: {
    maintenance: "In Maintenance",
    version: "version",
  },
  menu: {
    choiceTenants: "choisir une organisation", // TODO à traduire
    profile: "profile",
    authorizations: "authorizations",
    configuration: "configuration",
    billing: "factures et paiement",
  },
  cookies: {
    iAccept: "i accept",
    iRefuse: "i refuse",
    cookiePolicy: "policy about confidentiality and cookies",
    messageAcceptCookieP1:
      "Oblige ask you to accept our cookies to allow you the registration of your email address on the login page and on the forgot password page, it will allow you to have a better experience in our application. Accept cookie also register the last organisation or service you visited during your last connection and allow you to rejoin this organisation on your next connection. If you want more information about our cookies and the processing of your personal data, read our ",
    messageAcceptCookieP2: "in our",
    messageAcceptCookieP3:
      "Do you accept these cookies and the implications associated with the use of your personal data?",
    cookieParameter: "your cookies parameters",
    legalNotice: "legal notice",
  },
  profile: {
    password: "mot de passe",
    name: "name",
    language: "language",
    email: "email",
    mobilePhone: "mobile phone",
    displayName: "display name",
    myProfil: "my profile",
    slackChannel: "slack channel",
    slackId: "slack token",
    yourInformation: "your informations",
    communicationMethod: "how to join me ?",
    authenticationMode: "authentication mode",
    userRole: "user grant",
    profileTypes: {
      administrator: "administrator",
      manager: "manager",
      consultant: "consultant",
      guess: "guest",
    },
  },
  notification: {
    notifications: "notifications",
    noNotifications: "no notification",
  },
  authorization: {
    state: "state",
    lastActivity: "last activity",
  },
  // TODO checking traductions stop here
  //NOTE POKE
  osmose: {
    audio: {
      NORMALE: "NORMALE",
      SLIGHT: "SLIGHT",
      MILD: "MILD",
      MODERATE: "MODERATE",
      MODERATELY_SEVERE: "MODERATELY SEVERE",
      SEVERE: "SEVERE",
      PROFOUND: "PROFOUND",
      TOTAL: "TOTAL",
      NORMAL: "normale",
      CONDUCTIVE: "transmission",
      SENSORINEURAL: "perception",
      MIXED: "Mixte",
    },
    myosmose: "my osmose",
    question: "Questions",
    dashboard_cards: {
      leading: {
        title: "osmose Professionnel", // à traduire.
        btnSale: "vente en l'état", // à traduire.
        btnNewEquipment: "nouvel équipement", // à traduire.
      },
      rdvAdd: {
        title: "ajouter un rendez-vous", // à traduire.
        selectPatient: "selectionner un patient", // à traduire.
      },
      rdv: {
        title: "mes rendez-vous", // à traduire.
        addToCalendar: "ajouter à mon agenda", // à traduire.
        noData: "Vous n'avez pas de rendez-vous à venir", // à traduire.
      },
      myActions: {
        title: "mes raccourcis", // à traduire.
        noData: "aucun raccourcis n'est disponible", // à traduire.
      },
      addPatient: {
        title: "ajouter un patient", // à traduire.
        input_firstName: "first name",
        input_lastName: "last name",
        input_familyName: "family name",
      },
      searchPatient: {
        title: "rechercher un patient", // à traduire.
        radio: {
          label_extended: "Any criteria ",
          label_serialNumber: "numéro de série", // à traduire.
        },
      },
      todo: {
        title: "mes dossiers en cours", // à traduire.
        noData: "pas de dossiers en cours", // à traduire.
      },
      AddPatientNote: {
        title: "ajouter une note", // à traduire.
        selectPatient: "selectionner un patient", // à traduire.
      },
    },
    detailPatient_cards: {
      contact: {
        title: "contacts", // à traduire.
        noData: "aucune information de contact", // à traduire.
        formEmail: {
          title: "ajout d'une adresse email", // à traduire.
          inputName_label: "Nommer votre adresse e-mail", // à traduire.
          inputName_placeholder: "Ex: email professionnel", // à traduire.
          inputEmail_label: "Entrer l'adresse e-mail", // à traduire.
        },
        formMobile: {
          title: "ajout d'un numéro de téléphone", // à traduire.
          inputName_label: "nommer le numéro", // à traduire.
          inputName_placeholder: "Ex: tel domicile", // à traduire.
          inputNumber_label: "Entrer le numéro", // à traduire.
        },
        formAddress: {
          title: "ajout d'une adresse", // à traduire.
          btnEnterManualy: "passer en saisie manuelle", // à traduire.
          btnEnterAuto: "passer en saisie automatique", // à traduire.
          inputSearch_label: "rechercher l'adresse", // à traduire.
          inputName_label: "nom", // à traduire.
          inputName_placeholder: "Ex: adresse principal", // à traduire.
          inputNumber_label: "numéro", // à traduire.
          inputAddress_label: "adresse", // à traduire.
          inputAddress_placeholder: "Ex: rue de la république", // à traduire.
          inputPostalCode_label: "code postal", // à traduire.
          nputCity_label: "ville", // à traduire.
          inputAdditional_label: "complément d'adresse", // à traduire.
          inputAdditional_placeholder: "Ex: appartement 5", // à traduire.
        },
        formDelete: {
          title: "suppression", // à traduire.
          subtitle:
            "Attention ! Vous êtes sur le point de supprimer cette information :", // à traduire.
        },
      },
      survey: {
        title: "questionnaires", // à traduire.
        btnAddSurvey: "ajouter un questionnaire", // à traduire.
        noData: "il n'y a aucun questionnaire", // à traduire.
        dialog_title: "sélectionner un questionnaire",
      },
    },
    noMobilePhone: "no mobile phone",
  },
  // NOTE Vuetify
  $vuetify: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items...",
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}",
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year",
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} more",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}",
      },
    },
  },
};
