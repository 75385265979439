import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
// editeur HTML
// https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html
import CKEditor from "@ckeditor/ckeditor5-vue2";

// Editeur Markdown
// https://github.com/hinesboy/mavonEditor/blob/master/README-EN.md
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";

// VUEX - https://vuex.vuejs.org/
import store from "./store";

// VUE-ROUTER - https://router.vuejs.org/
import router from "./router";

// PLUGINS
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/vue-i18n";
import interceptor from "./class/interceptor";
// import "./plugins/vue-google-maps"; // on ne sait pas ou il est utilisé.
import "./plugins/vue-head";
import "./plugins/apexcharts";
import "./plugins/animate";
import "./plugins/clipboard";
import "./plugins/moment";
import VueCookies from "vue-cookies";

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css' // image viewer v-viewer.js
Vue.use(VueViewer)

// FILTERS
import "./filters/capitalize";
import "./filters/lowercase";
import "./filters/uppercase";
import "./filters/formatCurrency";
import "./filters/formatCurrencyFR";
import "./filters/formatDate";
import "./filters/round";
import "./filters/convertSizeFile";
import "./filters/strLimit";

//TOOLS
import { authorization } from "./tools/authorization";
import { rateBilling } from "./tools/rateBilling";
import { formatAmetropy } from "./tools/formatAmetropy";
// import { WriteLogs } from "./tools/logs/WriteLogs";

// STYLES
// Main Theme SCSS
import "./assets/scss/theme.scss";
import "./global.css";

// Personnal $prototype tools
import "./tools/generateUUID";

// Animation library - https://animate.style/
import "animate.css/animate.min.css";

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

// mettre et garder le token actif dans le header
const { userAuth } = store.getters;

interceptor();

if (userAuth.token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + userAuth.token;
}

axios.defaults.headers.common["x-app"] = process.env.VUE_APP_APPLICATION;
axios.defaults.headers.common["x-timezone"] =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

Vue.use(mavonEditor);
Vue.use(VueCookies);
Vue.use({ vuetify });
Vue.use(CKEditor);

Vue.prototype.$authorization = authorization;
Vue.prototype.$rateBilling = rateBilling;
Vue.prototype.$formatAmetropy = formatAmetropy;
// Vue.prototype.$WriteLogs = WriteLogs;

Vue.directive("blur", {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});

import RoutagePatient from "@/globalFunctions/RoutagePatient.js";
Vue.use(RoutagePatient);
Vue.prototype.$page_patient = RoutagePatient.page_patient;

import rules from "./formRules";
Vue.use(rules);
Vue.prototype.$rules = rules;

export default new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
