// Confluence : https://opteamwork.atlassian.net/l/cp/U0nAr611
// Jira : https://opteamwork.atlassian.net/browse/OSW-2854

export default {
  async page_patient(patientPath, options) {
    //  ! patientPath est requit. options = {forceRoute}

    // User preferences :

    let routeToPagePatientAuditory = false;

    const userPreferences = this.$store.state.userPreferences;
    if (userPreferences) {
      if (
        typeof userPreferences.automaticHealthOpen !== "undefined" &&
        userPreferences.automaticHealthOpen == true
      ) {
        routeToPagePatientAuditory = true;
      }
    }

    // Routage :

    if (patientPath) {
      let route = "detailsPatient";
      if (options && options.forceRoute) {
        // force la route choisi sans tenir compte des préférences user.
        route = options.forceRoute;
      } else if (routeToPagePatientAuditory) {
        route = "patientHealthAuditory";
      }

      // Si d'autres options comme des paramètres de route viennent à s'ajouter, il suffit de créer une variable pour l'object params de la route.

      await this.$router.push({
        name: route,
        params: { patientPath: patientPath },
      });
    } else {
      // pas de patientPath
      await this.$router.push({
        name: "myOsmose",
      });
    }
  },
};