const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit("hideToast");

  setTimeout(() => {
    commit("showToast", {
      color: "blue",
      message,
      timeout: 0
    });
  });
};

const showError = ({ state, commit }, { message = "Failed!", error }) => {
  if (state.toast.show) commit("hideToast");

  setTimeout(() => {
    commit("showToast", {
      color: "error",
      message: message + " " + error.message,
      timeout: 10000
    });
  });
};

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit("hideToast");

  setTimeout(() => {
    commit("showToast", {
      color: "success",
      message,
      timeout: 3000
    });
  });
};

export default {
  showToast,
  showError,
  showSuccess
};
