export default {
  form: {
    no: "non",
    yes: "oui",
    close: "fermer",
    send: "envoyer",
    delete: "supprimer",
    search: "rechercher",
    required: "obligatoire",
    validate: "valider",
    cancel: "Annuler",
    fieldsRequired: "certain champs obligatoires ne sont pas renseigner",
    invalidEmail: "email non valide",
    noAction: "pas d'action possible",
    securityPasswordDifferent: "les mots de passe sont différents",
    or: "ou",
    success: "opération effectué",
    error: "erreur",
    errorSelect: "sélectionner un champs",
    previous: "précèdent",
    next: "suivant",
    select: "sélectionner",
  },

  formRules: {
    required: "obligatoire",
    requiredNumber: "obligatoire",
    requiredSelectMultiple: "sélection obligatoire",
    invalidEmail: "email non valide",
    maxCharacters: "caractères maximum",
    passeNotIdentical: "les mots de passe ne sont pas identiques",
    numberMinRequired: "ne peut être inférieur à",
    numberMaxRequired: "ne peut être suppérieur à",
  },

  billing: {
    vatin: "TVA intracommunautraire",
    identifier: "SIREN",
    company: "Entreprise",
    billingAddress: "Adresse de facturation",
    contact: "contact",
    contactName: "Nom",
    contactEmail: "Email",
    contactPhone: "Téléphone",
    noBillingInformation:
      "vous n'avez pas encore enregistré d'informations de facturations",
    offerPack: "Offre spéciale",
    offerPackCode: "code",
    promoCodeInvalid: "le code promotionnel est invalide",
    facturation: "facturation",
  },
  rate: {
    rateCard: "plan tarifaire",
    rateCards: "plans tarifaires",
    noRateSelected: "vous n'avez aucun plan tarifaire de sélectionné",
    changeRate: "Changer de plan tarifaire",
    noDiscountActive: "Aucun code promotionnel actif",
    access: "access",
    essential: "essentiel",
    premium: "premium",
    month: "mois",
    twelveMonths: "engagement 12 mois",
    upTo: "jusqu'à {number} contrats",
    noEngagement: "sans engagement",
    oneUser: "1 seul utilisateur",
    numberUser: "jusqu'à {number} utilisateurs",
    contratheque: "contrathèque",
    notifications: "notifications automatiques",
    support: "support",
    email: "e-mail",
    chat: "chat",
    phone: "téléphone",
    mapExport: "export de la cartographie",
    trialPeriod: "essai gratuit 30 jours",
    moreContract: "vous avez au-delà de 250 contrats à stocker ?",
    consultOffer: "consultez-nous pour une offre personnalisée",
    moreQuestion: "Pour toute question, contactez-nous !",
    annualCheckout: "paiement annuel",
    mensualCheckout: "paiement mensuel",
    twentyDiscount: "Economisez 20%",
    expiryDate: "Expire le",
  },
  uploadFile: {
    sendingCompleted: "le fichier a bien été envoyé",
    sendingCompleteds: "les fichiers ont bien été envoyé",
    multipleSendingCompleted: "les {size} fichiers ont bien été envoyés",
    listUploadFiles: "liste des fichiers téléchargés",
    success_infoSendingFile:
      "votre fichier est désormais en cours d'analyse, vous serez notifié lorsqu'il sera pour revue",
    success_infoSendingFiles:
      "vos fichiers sont désormais en cours d'analyse, vous serez notifié lorsqu'ils seront pour revues",
    maxSize: "le fichier dépasse les {size} Mo",
    error_multipleFiles: "de vos fichiers n'ont pas pu être envoyés",
    error_singleFiles: "de vos fichiers n'a pas pu être envoyé",
    error: "votre fichier n'a pu être envoyé",
    errors: "vos fichiers n’ont pu être envoyer",
    warning: "{success} fichier(s) ont bien été envoyer et {error} en erreur ",
    invalideTokenOblige: "veuille vous reconnecter à Oblige merci",
  },

  convertDate: {
    years: "années",
    month: "mois",
    day: "jour",
    dayss: "jours",
    hours: "heures",
    thereIs: "il y à",
    in: "dans",
    and: "et",
    year: "an",
    from: "du",
    to: "au",
    every: "tous les",
    fromThe: "à partir du",
    the: "le",
    minute: "minutes",
  },
  date: {
    monday: "lundi",
    tuesday: "mardi",
    wednesday: "mercredi",
    thursday: "jeudi",
    friday: "vendredi",
    saturday: "samedi",
    sunday: "dimanche",
  },

  tags: {
    management: "Gestion des étiquettes",
    create: "cliquer sur entrer pour ajouter une étiquette",
    dialogTitle: "Gestion des étiquettes",
    errorMessages: {
      alreadyPresent: "ajout impossible ! l'étiquette est déjà présent",
      notStartSpace: "le nom ne doit pas commencer par un espace",
    },
  },

  generateMenu: {
    noAction: "aucune action disponible",
  },
  generateForm: {
    fieldsRequired: "certain champs obligatoires ne sont pas renseigner",
  },
  support: {
    support: "support",
    helpSupport: "contacter le support",
    generateSupportLink: "lien support",
    keySupport: "accès support",
    keyMsg: "merci de copier ce code est de le transmettre au support",
    copyToClipboard: "copier dans le presse-papier",
    close: "fermer",
    msgToClipBoard: "lien copier dans le presse papier",
    generateCodeSupport: "générer un code support",
    regenerateCodeSupport: "regénérer un code support",
    generateAbout:
      "le bouton ci dessous permet de générer un code que vous pourrez envoyer au support afin qu'ils accedent à votre espace",
  },
  cguCgv: {
    tos: "conditions d'utilisation",
    policy: "politique de confidentialité",
    agreeCGV: "j'accepte les",
  },
  password: {
    passeNotIdentical: "les mots de passe ne sont pas identiques",
    passwordPolicyOk: "votre mot de passe est valide",
    choosePassword: "Veuillez renseigner votre mot de passe",
    confirmPassword: "Veuillez confirmer votre mot de passe",
    yourPassword: "Votre mot de passe",
    passwordPolicyPattern:
      "votre mot de passe ne remplit pas toutes les conditions requises",
    passwordPolicyMaxLen:
      "votre mot de passe doit faire moins de {size} caractères",
    passwordPolicyMinLen: "doit contenir {size} caractères",
    passwordPolicyMinUpper: "doit contenir {size} majuscules",
    passwordPolicyMinDigit: "doit contenir {size} nombres",
    passwordPolicyMinSpecial: "doit contenir {size} caractères spécial",
    passwordPolicyHistory:
      "doit être différent de ceux utilisés il y a moins de {size}",
  },
  auth: {
    welcome: "bienvenue",
    choiceTenants: "Choisir mon espace de travail",
    returnSignInPage: "Retourner à la page de connexion",
    forgotPassword: "mot de passe oublié ?",
    resetPass: "réinitialiser",
    resetPassForm: "changer le mot de passe",
    changePass: "votre mot de passe va expiré",
    expiredPass: "votre mot de passe est expiré",
    confirmYour: "confirmer votre",
    loginPage: "page de connexion",
    signupPage: "Créez votre compte Osmose",
    forgotPage: "mot de passe oublié",
    loginButton: "connexion",
    logout: "déconnexion",
    noAccount: "Vous n'avez pas de compte ?",
    createAccount: "Créez-en un ici",
    registrationButton: "inscription",
    accountAlreadyExist: "vous avez déjà un compte ?",
    signinRedirection: "connectez-vous",
    forgotSubtitle:
      "entrez l'adresse e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    signInNOK:
      "mot de passe incorrect, verifiez si votre compte est valide ou si le couple login / mot de passe est correct",
    missingPassword:
      "aucun mot de passe n'est renseigné pour ce compte. Cliquez sur mot de passe oublié afin de le réinitialiser",
    changePassOk: "votre mot de passe a bien été changé",
    tokenExpired: "temps de session expiré veuillez vous reconnecter",
    passeNotIdentical: "les mots de passe ne sont pas identiques",
    toAllow: "vous n'étes pas autorisé à acceder à cette ressource",
    youHaveDaysLeftChangePwd:
      "il vous reste {days} jours pour changer votre mot de passe",
    changePwd: "voulez-vous changer le mot de passe ?",
    editMyPassword: "modifier mon mot de passe",
    signUpOk: "votre inscription a bien été prise en compte",
    userDisabled:
      "votre compte est désactivé, pour plus d'informations contacter le support.",
    securityUserUnknown:
      "le compte n'existe pas, si vous pensez que votre compte existe bien, contactez le support",
    securityTokenInvalid:
      "votre session est expirée, merci de vous reconnecter",
    securityAccessDenied:
      "vous n'êtes plus autorisés à vous connecter à la dernière organisation ou service auquel vous vous êtes connecté(e)s en dernier, essayez de vous reconnecter à nouveau.",
    dataTooMany: "l'email est indéterminé (multiples)",
    accountExists: "un compte avec cette adresse existe déjà.",
    modalLiveText: "le compte attaché à l'adresse",
    modalLiveTextAfter:
      "existe déjà. En cas d'oubli, vous pouvez changer votre mot de passe. Dans ce cas un email vous sera adressé contenant un lien vous permettant de le réinitialiser.",
    modalLiveTextAction:
      "souhaitez-vous réinitialiser le mot de passe de ce compte ?",
    accountUnconfirmedSignUp:
      "Ce compte éxiste déjà mais n'a jamais été validé",
    textSignUp:
      "nous avons bien pris en compte votre demande de création de compte. Vous recevrez très prochainement un email à l'adresse",
    textSignUpAfter:
      "contenant les instructions à suivre pour accéder à Osmose.",
    titlePwdForgot: "demande de renouvellement de mot de passe effectuée",
    textPwdForgot: "un email a été envoyé à l'adresse",
    textPwdForgotAfter:
      "contenant un lien de réinitialisation de votre mot de passe.",
    registrationDate: "date de première inscription :",
    pwdValidateDuration: "votre nouveau mot de passe sera valide pendant",
    userDisabledTitle: "compte utilisateur désactivé",
    renewCredentialsExpired:
      "il est possible que la période de renouvellement du mot de passe soit dépassée, dans ce cas veuillez cliquer sur mot de passe oublié",
    passwordPolicyHistory:
      "Ne doit pas avoir été utilisé depuis moins de {value}",
    passwordPolicyMinDigit: "contenir au moins {value} chiffre",
    passwordPolicyMinLen: "avoir une taille minimale de {value} caractères",
    passwordPolicyMinSpecial: "contenir au moins {value} caractère spécial",
    passwordPolicyMinUpper: "contenir au moins {value} majuscule",
    passwordValidity: "il sera valide pendant {value}",
    passwordHadToContain: "Votre mot de passe doit :",
  },
  utility: {
    maintenance: "En maintenance",
    version: "version",
  },
  menu: {
    choiceTenants: "choisir une organisation",
    profile: "mon profil utilisateur",
    authorizations: "gérer mon espace",
    configuration: "configuration",
    billing: "factures et paiement",
  },
  cookies: {
    iAccept: "j'accepte",
    iRefuse: "je refuse",
    cookiePolicy: "politique en matière de confidentialité et de cookies",
    messageAcceptCookieP1:
      "Osmose vous demande d'accepter ses cookies afin de vous permettre la sauvegarde de votre adresse email sur la page de connexion vous permettant ainsi de gagner en efficacité lors de votre connexion ou d'une éventuelle perte de mot de passe, mais également pour vous reconnecter sur la dernière organisation ou établissement que vous avez visité lors de votre dernière connexion. Pour obtenir davantage d’information sur ces cookies et le traitement de vos données personnelles, consultez notre ",
    messageAcceptCookieP2: "sur nos",
    messageAcceptCookieP3:
      "Acceptez-vous ces cookies ainsi que les implications associées à l’utilisation de vos données personnelles ?",
    cookieParameter: "vos paramètres de cookies",
    legalNotice: "mentions Légales",
  },
  profile: {
    password: "mot de passe",
    name: "nom",
    language: "langue",
    email: "email",
    mobilePhone: "téléphone mobile",
    displayName: "nom d'affichage",
    myProfil: "mon profil",
    slackChannel: "canal slack",
    slackId: "token slack",
    yourInformation: "vos informations",
    communicationMethod: "comment me joindre ?",
    country: "pays",
    authenticationMode: "mode de connexion",
    userRole: "profil",
    profileTypes: {
      administrator: "administrateur",
      manager: "gestion",
      consultant: "consultant",
      guess: "invité",
    },
  },
  notification: {
    notifications: "notifications",
    noNotifications: "vous n'avez aucun message",
  },
  authorization: {
    state: "etat",
    lastActivity: "derniere activité",
  },
  // TODO checking traductions stop here
  //NOTE POKE
  osmose: {
    hello: "bonjour",
    acceptance: "En cliquant sur le bouton d'inscription, vous acceptez les",
    later: "plus tard",
    audio: {
      NORMALE: "normale",
      SLIGHT: "très légère",
      MILD: "légère",
      MODERATE: "modérée",
      MODERATELY_SEVERE: "sévère",
      SEVERE: "très sévère",
      PROFOUND: "profond",
      TOTAL: "perte totale",
      NORMAL: "normale",
      CONDUCTIVE: "transmission",
      SENSORINEURAL: "perception",
      MIXED: "Mixte",
    },
    IN_PROGRESS: "en cours",
    PROCESSED: "terminée",
    FAILED: "echec",
    myosmose: "mon osmose",
    question: "Questions",
    dashboard_cards: {
      leading: {
        title: "osmose Professionnel",
        btnSale: "vente en l'état",
        btnNewEquipment: "nouvel équipement",
      },
      rdvAdd: {
        title: "ajouter un rendez-vous",
        selectPatient: "selectionner un patient",
      },
      rdv: {
        title: "mes rendez-vous",
        addToCalendar: "ajouter à mon agenda",
        noData: "aucun rendez-vous",
      },
      myActions: {
        title: "mes raccourcis",
        noData: "aucun raccourcis n'est disponible",
      },
      addPatient: {
        title: "ajouter un patient",
        input_firstName: "prénom",
        input_lastName: "nom",
        input_familyName: "nom de jeune fille",
      },
      searchPatient: {
        title: "rechercher un patient",
        radio: {
          label_extended: "tout critère",
          label_serialNumber: "numéro de série",
        },
      },
      todo: {
        title: "mes dossiers en cours",
        noData: "pas de dossiers en cours",
      },
      AddPatientNote: {
        title: "ajouter une note",
        selectPatient: "selectionner un patient",
      },
    },
    detailPatient_cards: {
      contact: {
        title: "contacts",
        noData: "aucune information de contact",
        formEmail: {
          title: "ajout d'une adresse email",
          inputName_label: "Nommer votre adresse e-mail",
          inputName_placeholder: "Ex: email professionnel",
          inputEmail_label: "Entrer l'adresse e-mail",
        },
        formMobile: {
          title: "ajout d'un numéro de téléphone",
          inputName_label: "nommer le numéro",
          inputName_placeholder: "Ex: tel domicile",
          inputNumber_label: "Entrer le numéro",
        },
        formAddress: {
          title: "ajout d'une adresse",
          btnEnterManualy: "passer en saisie manuelle",
          btnEnterAuto: "passer en saisie automatique",
          inputSearch_label: "rechercher l'adresse",
          inputName_label: "nom",
          inputName_placeholder: "Ex: adresse principal",
          inputNumber_label: "numéro",
          inputAddress_label: "adresse (numéro + nom de la rue)",
          inputAddress_placeholder: "Ex: 18 rue de la république",
          inputPostalCode_label: "code postal",
          inputCity_label: "ville",
          inputAdditional_label: "complément d'adresse",
          inputAdditional_placeholder: "Ex: appartement 5",
        },
        formDelete: {
          title: "suppression",
          subtitle:
            "Attention ! Vous êtes sur le point de supprimer cette information :",
        },
      },
      survey: {
        title: "questionnaires",
        btnAddSurvey: "ajouter un questionnaire",
        noData: "il n'y a aucun questionnaire",
        dialog_title: "sélectionner un questionnaire",
      },
    },
    comingSoon: "Bientôt disponible",
    noMobilePhone: "numéro de téléphone non indiqué",
  },
  //NOTE Vuetify
  $vuetify: {
    badge: "Badge",
    close: "Fermer",
    dataIterator: {
      noResultsText: "Aucun enregistrements correspondants trouvés",
      loadingText: "Chargement des éléments ...",
    },
    dataTable: {
      itemsPerPageText: "Lignes par page:",
      ariaLabel: {
        sortDescending: "Tri par ordre décroissant.",
        sortAscending: "Tri par ordre croissant.",
        sortNone: "Pas trié.",
        activateNone: "Activez pour supprimer le tri.",
        activateDescending: "Activez pour trier par ordre décroissant.",
        activateAscending: "Activez pour trier par ordre croissant.",
      },
      sortBy: "Trier par",
    },
    dataFooter: {
      itemsPerPageText: "Objets par page:",
      itemsPerPageAll: "Tout",
      nextPage: "Page suivante",
      prevPage: "Page précédente",
      firstPage: "Première page",
      lastPage: "Dernière page",
      pageText: "{0} - {1} sur {2}",
    },
    datePicker: {
      itemsSelected: "{0} sélectionné",
      nextMonthAriaLabel: "Le mois prochain",
      nextYearAriaLabel: "L'année prochaine",
      prevMonthAriaLabel: "Le mois précédent",
      prevYearAriaLabel: "Année précédente",
    },
    noDataText: "Pas de données disponibles",
    carousel: {
      prev: "Visuel précédent",
      next: "Visuel suivant",
      ariaLabel: {
        delimiter: "Diapositive du carrousel {0} sur {1}",
      },
    },
    calendar: {
      moreEvents: "{0} de plus",
    },
    fileInput: {
      counter: "{0} fichiers",
      counterSize: "{0} fichiers ({1} au total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Navigation par pagination",
        next: "Page suivante",
        previous: "Page précédente",
        page: "Aller à la page {0}",
        currentPage: "Page actuelle, page {0}",
      },
    },
  },
};
