import axios from "axios";

let API = process.env.VUE_APP_URL_HORUS;

export default class quote {
  // QUOTE
  async add_new_quote(apiKey, patientPath, nature) {
    let request = await axios.post(
      API +
        "osmose/quote/dummy?patientUUID=" +
        patientPath +
        "&nature=" +
        nature,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // devis libre uniquement:
  async add_new_freeQuote(apiKey, nature, withRecord) {
    let request = await axios.post(
      API + "osmose/quote/dummy?nature=" + nature + "&withRecord=" + withRecord,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  // fin devis libre

  async get_all_quotes_of_patient(
    apiKey,
    patientPath,
    size = null,
    currentPage = null
  ) {
    let url = "osmose/quote/patient?patientUUID=" + patientPath;
    if (size != null && currentPage != null) {
      url =
        "osmose/quote/patient?patientUUID=" +
        patientPath +
        "&size=" +
        size +
        "&page=" +
        currentPage;
    }

    let request = await axios.post(
      API + url,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_patient_closed_quotes(apiKey, patientPath, size, currentPage) {
    let request = await axios.post(
      API +
        "osmose/quote/patient/closed?patientUUID=" +
        patientPath +
        "&size=" +
        size +
        "&page=" +
        currentPage,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_quote_details(apiKey, quoteUUID) {
    let request = await axios.post(
      API + "osmose/quote?quoteUUID=" + quoteUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_quote(apiKey, quoteUUID, reasonUUID = null) {
    let api = "osmose/quote/cancel?quoteUUID=" + quoteUUID;
    if (reasonUUID != null) {
      api =
        "osmose/quote/cancel?quoteUUID=" + quoteUUID + "&reason=" + reasonUUID;
    }
    let request = await axios.post(
      API + api,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_quote_by_record(apiKey, recordUUID, reasonUUID = null) {
    let api = "osmose/quote/cancel?recordUUID=" + recordUUID;
    if (reasonUUID != null) {
      api =
        "osmose/quote/cancel?recordUUID=" +
        recordUUID +
        "&reason=" +
        reasonUUID;
    }
    let request = await axios.post(
      API + api,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async quote_store(apiKey, quoteUUID, body) {
    let request = await axios.post(
      API + "osmose/quote/store?quoteUUID=" + quoteUUID,
      {
        quoteDate: body.quoteDate ? body.quoteDate : null,
        expiryDate: body.expiryDate ? body.expiryDate : null,
        quoteValidity: body.quoteValidity ? body.quoteValidity : null,
        quoteLocation: body.quoteLocation ? body.quoteLocation : null,
        renewalMode: body.renewalMode ? body.renewalMode : null,
        renewalDate: body.renewalDate ? body.renewalDate : null,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async assign_quote_to_me(apiKey, quoteUUID) {
    const request = await axios.post(
      API + "osmose/quote/store?quoteUUID=" + quoteUUID,
      {
        assignToMe: true,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async count_quote(apiKey) {
    let request = await axios.post(
      API + "osmose/quote/parks/count",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async parks_quote(apiKey, size, page) {
    let request = await axios.post(
      API + "osmose/quote/parks?size=" + size + "&page=" + page,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async quote_close(apiKey, quoteUUID, park) {
    // park = true => pause - park = false => enregistrez
    let request = await axios.post(
      API + "osmose/quote/close?quoteUUID=" + quoteUUID + "&park=" + park,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async win_quote(apiKey, quoteUUID, records) {
    let request = await axios.post(
      API + "osmose/quote/win?quoteUUID=" + quoteUUID,
      {
        records: records,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async record_win_quote(apiKey, recordUUID, records) {
    let request = await axios.post(
      API + "osmose/quote/win?recordUUID=" + recordUUID,
      {
        records: records,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async propose_quote(apiKey, quoteUUID) {
    let request = await axios.post(
      API + "osmose/quote/submit?quoteUUID=" + quoteUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Quote reasons
  async get_reasons_lose_quote_selectable(apiKey) {
    let request = await axios.post(
      API + "osmose/static/quote/reason/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_reasons_lose_quote(apiKey, form) {
    let request = await axios.post(API + "osmose/static/quote/reason", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async set_reason_lose_quote(apiKey, form) {
    let request = await axios.post(
      API + "osmose/static/quote/reason/set",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // OFFER (record)
  async get_patient_records(apiKey, patientUUID) {
    let request = await axios.post(
      API + "osmose/record/patient?patientUUID=" + patientUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async add_record_to_quote(apiKey, quoteUUID, profilUUID = null) {
    let url = "osmose/quote/dummy/record?quoteUUID=" + quoteUUID;
    if (profilUUID != null) {
      url =
        "osmose/quote/dummy/record?quoteUUID=" +
        quoteUUID +
        "&profilUUID=" +
        profilUUID;
    }
    let request = await axios.post(
      API + url,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async remove_record(apiKey, recordUUID) {
    let request = await axios.post(
      API + "osmose/record/remove?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async cancel_record(apiKey, recordUUID) {
    let request = await axios.post(
      API + "osmose/record/cancel?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async trial_cancel(apiKey, recordUUID) {
    let request = await axios.post(
      API + "osmose/record/cancel?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async trial_offer(apiKey, recordUUID) {
    let request = await axios.post(
      API + "osmose/record/trial?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async record_trial_start(apiKey, recordUUID, data) {
    let request = await axios.post(
      API + "osmose/record/trial/start?recordUUID=" + recordUUID,
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async list_trial(apiKey, patientUUID) {
    let request = await axios.post(
      API + "osmose/patient/delivery/trial?patientUUID=" + patientUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async health_ventilation(apiKey, recordUUID) {
    let request = await axios.post(
      API + "osmose/record/health?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async update_health_ventilation(apiKey, recordUUID, health) {
    let request = await axios.post(
      API + "osmose/record/health/update?recordUUID=" + recordUUID,
      health,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async update_health_prestation(apiKey, recordUUID, prestation) {
    let request = await axios.post(
      API + "osmose/record/health/prestation/update?recordUUID=" + recordUUID,
      prestation,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async update_title_record(apiKey, recordUUID, body) {
    let request = await axios.post(
      API + "osmose/record/update?recordUUID=" + recordUUID,
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // OFFER model
  // FIXME Deprecier
  // async record_save_as_model(
  //   apiKey,
  //   recordUUID,
  //   label,
  //   perimeter = "hearingOffer",
  //   familly = null
  // ) {
  //   let request = await axios.post(
  //     API + "osmose/record/profils/add?recordUUID=" + recordUUID,
  //     {
  //       label: label,
  //       perimeter: perimeter,
  //       familly: familly,
  //     },
  //     {
  //       headers: {
  //         "x-tenant": apiKey,
  //       },
  //     }
  //   );
  //   return request.data;
  // }

  async record_save_as_model_V2(apiKey, recordUUID, form) {
    let request = await axios.post(
      API + "osmose/record/profils/add?recordUUID=" + recordUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async edit_as_model(apiKey, form) {
    let request = await axios.post(API + "osmose/record/profils/update", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_list_models(apiKey, perimeter = "hearingOffer") {
    let request = await axios.post(
      API + "osmose/record/profils?perimeter=" + perimeter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async delete_model(apiKey, profilUUID) {
    let request = await axios.post(
      API + "osmose/record/profils/delete?profilUUID=" + profilUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async apply_model_in_record(apiKey, profilUUID, recordUUID) {
    let request = await axios.post(
      API +
        "osmose/record/profils/apply?profilUUID=" +
        profilUUID +
        "&recordUUID=" +
        recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return record.
  }

  async list_model_offer_famillies(apiKey, lensOffer = false) {
    let url = "osmose/record/profils/famillies";
    if (lensOffer) {
      url = "osmose/record/profils/famillies?perimeter=lensOffer";
    }
    const request = await axios.post(
      API + url,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async list_offer_famillies(apiKey) {
    let request = await axios.post(
      API + "osmose/record/profils/static/famillies",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // OFFER / prestation

  async add_prestation_to_record(apiKey, recordUUID, prestation) {
    let request = await axios.post(
      API + "osmose/record/prestation?recordUUID=" + recordUUID,
      prestation,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async remove_prestation(apiKey, prestationUUID) {
    let request = await axios.post(
      API + "osmose/record/prestation/remove?prestationUUID=" + prestationUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async clone_prestation(apiKey, prestationUUID, perimeter) {
    let request = await axios.post(
      API +
        "osmose/record/prestation/clone?prestationUUID=" +
        prestationUUID +
        "&perimeter=" +
        perimeter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_power_unit(apiKey, patientUUID) {
    let request = await axios.post(
      API + "osmose/record/powerUnit/init?patientUUID=" + patientUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async post_power_unit(apiKey, patientUUID, powerUnit) {
    let request = await axios.post(
      API + "osmose/record/powerUnit?patientUUID=" + patientUUID,
      powerUnit,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  // HEALTH

  async assign_counterpartie_to_quote(apiKey, quoteUUID, nature, uuid = null) {
    // Le uuid peut etre laisser vide pour supprimer l'association au devis.
    let url =
      "osmose/quote/health/link?quoteUUID=" +
      quoteUUID +
      "&nature=" +
      nature +
      "&uuid=" +
      uuid;
    if (uuid == null) {
      url =
        "osmose/quote/health/link?quoteUUID=" + quoteUUID + "&nature=" + nature;
    }

    let request = await axios.post(
      API + url,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async align_legislation(apiKey, prestationUUID) {
    let request = await axios.post(
      API + "osmose/record/alignLegislation?prestationUUID=" + prestationUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_renewal_list(apiKey, quoteNature) {
    let request = await axios.post(
      API + "osmose/static/quote/renewalMode?quoteNature=" + quoteNature,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // campaign
  async select_campaign_quote(apiKey, quoteUUID, campaignUUID) {
    let request = await axios.post(
      API + "osmose/quote/store/campaign?quoteUUID=" + quoteUUID,
      { campaignUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return quote.
  }

  // patient
  async assign_patient_to_quote(apiKey, quoteUUID, patientUUID) {
    let request = await axios.post(
      API + "osmose/quote/patient/link?quoteUUID=" + quoteUUID,
      { patientUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return quote.
  }

  async get_devices_patient(apiKey, patientUUID) {
    let request = await axios.post(
      API + "osmose/patient/delivery/live?patientUUID=" + patientUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return quote.
  }

  async get_devices_patient_history(apiKey, patientUUID, size, page) {
    let request = await axios.post(
      API +
        "osmose/patient/delivery/live/all?patientUUID=" +
        patientUUID +
        "&size=" +
        size +
        "&page=" +
        page,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return quote.
  }

  // livrer le patient
  async prepare_deliver_patient(apiKey, recordUUID) {
    let request = await axios.post(
      API + "osmose/record/deliver/prepare?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return quote.
  }

  async deliver_patient(apiKey, recordUUID, articles) {
    let request = await axios.post(
      API + "osmose/record/deliver?recordUUID=" + recordUUID,
      articles,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data; // return quote.
  }

  // RECORD OVERVIEW

  async get_current_folders(apiKey) {
    let request = await axios.post(
      API + "osmose/record/overview",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // EQUIPMENT QUOTE

  async add_new_quote_equipment(apiKey, patientUUID, nature) {
    let request = await axios.post(
      API +
        "osmose/quote/dummy?patientUUID=" +
        patientUUID +
        "&nature=" +
        nature +
        "&withRecord=true",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async save_equipment_elsewhere(apiKey, equipment) {
    let request = await axios.post(API + "osmose/record/elseWhere", equipment, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // async get_detail_quote_equipment(apiKey, quoteUUID) {
  //   let request = await axios.post(
  //     API + "osmose/quote?quoteUUID=" + quoteUUID,
  //     {},
  //     {
  //       headers: {
  //         "x-tenant": apiKey
  //       }
  //     }
  //   );
  //   return request.data;
  // }

  async get_quotes_backoffice(apiKey, size, page, form = null) {
    const request = await axios.post(
      API + "osmose/quote/search" + "?size=" + size + "&page=" + page,
      form ? form : {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async health_ask(apiKey, data) {
    const request = await axios.post(API + "osmose/record/health/ask", data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_prestation_activities(apiKey, size, page, form) {
    // BackOffice
    const request = await axios.post(
      API + "osmose/record/activities?page=" + page + "&size=" + size,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // OPTIC

  async get_record_optic_measure(apiKey, recordUUID) {
    const request = await axios.post(
      API + "osmose/record/optic/measure?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async update_record_optic_measure(apiKey, recordUUID, form) {
    const request = await axios.post(
      API + "osmose/record/optic/measure/store?recordUUID=" + recordUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_back_flux_oma(apiKey, recordUUID) {
    // Pour colonne de vision
    const request = await axios.post(
      API + "osmose/record/optic/measure/activisu?recordUUID=" + recordUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async post_back_flux_oma(apiKey, recordUUID, flux) {
    // Pour colonne de vision
    const request = await axios.post(
      API +
        "osmose/record/optic/measure/store/activisu?recordUUID=" +
        recordUUID,
      flux,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
