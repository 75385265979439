import Vue from "vue";
const filters = Vue.options.filters;

import vuetify from "./plugins/vuetify";
let t = (t) => vuetify.framework.lang.translator(t);

export default {
  // Auth only
  passwordIdentical: (value, pass1) =>
    value === pass1 || filters.capitalize(t("formRules.passeNotIdentical")),

  // Common
  required: (value) => !!value || filters.capitalize(t("formRules.required")),
  requiredNumber: (v) =>
    ((!!v || v === 0) && v.toString().length != 0 && v != null) ||
    filters.capitalize(t("formRules.requiredNumber")),
  requiredSelectMultiple: (v) =>
    (v || "").length > 0 ||
    filters.capitalize(t("formRules.requiredSelectMultiple")),
  maxLength: (len) => (v) =>
    (v || "").length <= len ||
    `${len} ${filters.capitalize(t("formRules.maxCharacters"))}`,
  minNumber: (length) => (v) =>
    v >= length ||
    `${filters.capitalize(t("formRules.numberMinRequired"))} ${length}`,
  maxNumber: (length) => (v) =>
    v <= length ||
    `${filters.capitalize(t("formRules.numberMaxRequired"))} ${length}`,
  emailValid: (v) =>
    (v || "").length == 0 ||
    /.+@.+\..+/.test(v) ||
    filters.capitalize(t("formRules.invalidEmail")),
  
  // NOTE remember to add the translation in the lang/object formRules files.
};

// NOTE How to use ===> :rules="[$rules.required]"
