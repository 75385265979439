import axios from "axios";
const urlApi = process.env.VUE_APP_URL_HORUS;

export default class Doctolib {
  async set_doctolib_account(apiKey, form) {
    const request = await axios.post(urlApi + "doctolib/context/store", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_doctolib_account(apiKey, contextKey) {
    const request = await axios.get(urlApi + "doctolib/context/" + contextKey, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_list_doctolib_accounts(apiKey) {
    const request = await axios.get(urlApi + "doctolib/context/list", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async subscribe_shop_to_doctolib(apiKey, contextKey) {
    const request = await axios.post(
      urlApi + "osmose/shop/subscribe/doctolib?contextKey=" + contextKey,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async unsubscribe_shop_to_doctolib(apiKey, contextKey) {
    const request = await axios.post(
      urlApi +
        "osmose/shop/subscribe/doctolib?contextKey=" +
        contextKey +
        "&subscribe=false",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_current_doctolib_account(apiKey) {
    const request = await axios.get(urlApi + "doctolib/context/current", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async test_doctolib_account(apiKey, contextKey) {
    const request = await axios.get(
      urlApi + "doctolib/context/test?contextKey=" + contextKey,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async export_agenda(apiKey) {
    const request = await axios.get(urlApi + "doctolib/config/export", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async sync_agenda(apiKey) {
    const request = await axios.post(
      urlApi + "doctolib/config/export",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_osmose_patient_by_doctolib_id(apiKey, doctolibId) {
    const request = await axios.get(
      urlApi + "doctolib/map/patientPath?doctolibId=" + doctolibId,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_doctolib_patient_by_osmose_id(apiKey, patientPath) {
    const request = await axios.get(
      urlApi +
        "doctolib/map/doctolibIdentifier?patientPath=" +
        patientPath +
        "&safely=true",
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async current_context(apiKey) {
    const request = await axios.get(urlApi + "doctolib/context/current", {
      headers: { "x-tenant": apiKey },
    });
    return request.data;
  }
}
