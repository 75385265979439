import axios from "axios";
import store from "../store/index";

export default class Asyncblobmanagement {
  // Document
  async read_blob(blob) {
    let dataUrl = "";
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    await new Promise((resolve) => (reader.onload = () => resolve()));
    dataUrl = reader.result;

    axios.get(dataUrl).then((response) => {
      store.commit("upd_errorMessageBlob", response.data);
    });
  }
}
