export default {
  // global theme for the app
  globalTheme: "light", // light | dark

  // side menu theme, use global theme or custom
  menuTheme: "global", // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: "global", // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    bgLvl0: "#121212", // pages
    bgLvl1: "#424242", // widgets
    bgLvl2: "#1F1F1F", // cards sur widgets
    bgLvl3: "#424242", // dialog fond
    soft: "#f3f7f7",
    NavPrimary: "#424242",
    NavSecondary: "#5CCBE5",
    primary: "#329EF4",
    secondary: "#0097A7",
    accent: "#FFF500",
    active: "#039BE5",
    // alerts
    info: "#039BE5",
    error: "#FF5252",
    success: "#4caf50",
    warning: "#fb8c00"
  },

  // light theme colors
  light: {
    bgLvl0: "#ffffff", // pages
    bgLvl1: "#f2f3f6", // widgets
    bgLvl2: "#ffffff", // cards sur widgets
    bgLvl3: "#ffffff", // dialog fond
    soft: "#f3f7f7",
    NavPrimary: "#093A49",
    NavSecondary: "#0F5E76",
    primary: "#093A49",
    secondary: "#0F5E76", //#5CCBE5 old
    accent: "#FFF500",
    active: "#039BE5",
    // alerts
    info: "#039BE5",
    error: "#FF5252",
    success: "#4caf50",
    warning: "#fb8c00"
  }
};

// origine LUX
//   background: "#ffffff",
//   surface: "#f2f5f8",
//   primary: "#0096c7",
//   secondary: "#a0b9c8",
//   accent: "#048ba8",
//   error: "#ef476f",
//   info: "#2196F3",
//   success: "#06d6a0",
//   warning: "#ffd166"
