<template>
  <v-app>
    <div style="min-height: 100vh; width: 100vw" id="elApp">
      <!-- :class="
        $vuetify.theme.dark ? 'backgroundPagesDark' : 'backgroundPagesLight'
      " -->
      <!-- Layout component -->
      <component :is="currentLayout" v-if="isRouterLoaded">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.path" />
        </transition>
      </component>
    </div>

    <SnackbarNotification v-if="this.notification.open" />
    <IsUpdateBuild />
    <InitCustomTheme v-if="isRouterLoaded" :currentLayout="currentLayout" />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import config from "./configs";
import Doctolib from "@/class/doctolib.js";

// Layouts
const defaultLayout = () => import("./layouts/DefaultLayout");
const authLayout = () => import("./layouts/AuthLayout");
const errorLayout = () => import("./layouts/ErrorLayout");
const surveyLayout = () => import("./layouts/SurveyLayout");
const managementLayout = () => import("./layouts/ManagementLayout.vue");
const PublicLayout = () => import("@/layouts/PublicLayout.vue");

const SnackbarNotification = () =>
  import("./components/common/SnackbarNotification.vue");
const IsUpdateBuild = () =>
  import("./components/isUpdateBuild/IsUpdateBuild.vue");

export default {
  name: "App",
  components: {
    defaultLayout,
    authLayout,
    errorLayout,
    surveyLayout,
    managementLayout,
    PublicLayout,
    SnackbarNotification,
    IsUpdateBuild,
    InitCustomTheme: () =>
      import(
        "./components/userSpace/userSpaceConfigurations/elements/customTheme/InitCustomTheme.vue"
      ),
  },

  computed: {
    ...mapGetters(["notification", "apiKey"]),
    ...mapState("app", ["toast"]),
    isRouterLoaded: function () {
      if (this.$route.name !== null) return true;
      return false;
    },
    currentLayout: function () {
      const layout = this.$route.meta.layout || "default";
      return layout + "Layout";
    },
  },

  data() {
    return {
      ClassDoctolib: null,
      // Detection keyDown
      keyCodes: [],
      onKeyDown: null,
      // timeout: null,
    };
  },

  watch: {
    $route() {
      if (this.$route.query.doctolibId != null) {
        let id = this.$route.query.doctolibId.split("=")[1];
        this.open_patient(id); // TODO Ajouter un loading pour la redirection. (du genre Overlay avec un loading et un message)
      }
    },
  },

  created() {
    // Detection keyDown
    this.onKeyDown = this.on_key_Down.bind(this);
    document.addEventListener("keydown", this.onKeyDown);
  },

  mounted() {
    if (this.ClassDoctolib == null) {
      this.ClassDoctolib = new Doctolib();
    }
    this.$vuetify.theme.dark = this.$store.state.themeDark;
    window.zipper =
      window.zipper ||
      function () {
        (zipper.q = zipper.q || []).push(arguments);
      };
    window.zipper("onOpenPatient", function onOpenPatient(patient) {
      var params = new URLSearchParams();
      params.append("pmsId", patient.pms_id);
      window.location = "/#/mon-osmose?doctolibId=" + String(params);
    });
  },

  beforeDestroy() {
    clearInterval(this.timeout);
  },

  destroyed() {
    // Detection keyDown
    if (this.onKeyDown) {
      document.removeEventListener("keydown", this.onKeyDown);
    }
  },

  methods: {
    async open_patient(pmsId) {
      try {
        window.location = "/#/mon-osmose/";
        const response =
          await this.ClassDoctolib.get_osmose_patient_by_doctolib_id(
            this.apiKey,
            encodeURIComponent(pmsId)
          );
        await this.$router.push({
          name: "detailsPatient",
          params: { patientPath: response },
        });
      } catch (error) {
        console.error(error);
      }
    },
    // Detection keyDown
    on_key_Down(e) {
      // ctrl -> x -> d
      this.keyCodes.push(e.keyCode);
      if (this.keyCodes.length > 2) {
        if (
          this.keyCodes[this.keyCodes.length - 1] === 68 &&
          this.keyCodes[this.keyCodes.length - 2] === 88 &&
          this.keyCodes[this.keyCodes.length - 3] === 17
        ) {
          this.$store.commit("upd_theme_dark", !this.$vuetify.theme.dark);
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        }
        if (this.keyCodes.length > 3) {
          this.keyCodes.splice(0, 1);
        }
      }
    },
  },

  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: "stylesheet", href })),
    ],
  },
};
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
