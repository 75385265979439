export const formatAmetropy = {
  returnTextFormatAmetropy(ametropy, showAxis = true) {
    let f = "";
    if (ametropy.sphere && Number.parseFloat(ametropy.sphere) != 0) {
      f =
        f +
        (Number.parseFloat(ametropy.sphere) < 0 ? "-" : "+") +
        Number.parseFloat(ametropy.sphere).toFixed(2);
    } else {
      f = f + "Plan";
    }
    if (ametropy.cylinder && Number.parseFloat(ametropy.cylinder) != 0) {
      f =
        f +
        " (" +
        (Number.parseFloat(ametropy.cylinder) < 0 ? "-" : "+") +
        Number.parseFloat(ametropy.cylinder).toFixed(2) +
        ")";
      if (showAxis && Number.parseFloat(ametropy.axis)) {
        f = f + " " + Number.parseFloat(ametropy.axis).toFixed(0) + "°";
      }
    }
    if (ametropy.addition && Number.parseFloat(ametropy.addition) != 0) {
      f = f + " ADD +" + Number.parseFloat(ametropy.addition).toFixed(2);
    }
    return f;
  },
};
